import React from "react";
import TableAll from "../../../Layout/MainTable/Table";
import AutoExpensesMainTable from "./AutoExpensesMainTable";

const AutoExpenses = () => {
  return (
    <>
      <div className="page-content">
        {/* <TableAll /> */}
        <AutoExpensesMainTable/>
      </div>
    </>
  );
};

export default AutoExpenses;
