// import React, { useState } from "react";
// import { withProfile } from "../../contexts/profileContext";
// import axios from "axios";

// const PaymentPage = ({ validatedProfile }) => {
//   const [billingDetails, setBillingDetails] = useState({
//     plan: "",
//     users: "" || 0,
//     company: "" || 0,
//     number: validatedProfile.mobile,
//     firstName: validatedProfile.name.split(" ")[0] || "",
//     lastName: validatedProfile.name.split(" ")[1] || "",
//     email: validatedProfile.email,
//     discountCode: "" || null,
//     total: "",
//     userId: validatedProfile.userid,
//   });

//   console.log("validatedProfile", validatedProfile.userid);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;

//     setBillingDetails((prev) => {
//       const updatedDetails = { ...prev, [name]: value };

//       if (name === "plan" && value === "10000") {
//         updatedDetails.company = 0;
//       }

//       return updatedDetails;
//     });
//   };

//   const validateForm = () => {
//     if (!billingDetails.firstName || !billingDetails.lastName || !billingDetails.email || !billingDetails.plan) {
//       alert("Please fill all required fields.");
//       return false;
//     }
//     return true;
//   };

//   const calculateTotal = () => {
//     const planPrice = parseInt(billingDetails.plan, 10);
//     const users = parseInt(billingDetails.users, 10);
//     const company = billingDetails.plan != 10000 ? parseInt(billingDetails.company, 10) : 0;
//     const subtotal = 500 * users || 0;
//     const subcompany = 500 * company;
//     const discount = billingDetails.discountCode === "SAVE10" ? ((planPrice + subtotal + subcompany) * 10) / 100 : 0;
//     const total = planPrice + subtotal + subcompany - discount || 0;
//     billingDetails["total"] = total;
//     billingDetails["discount"] = discount;
//     return { subtotal, subcompany, discount, total };
//   };

//   const handleSubmit = async () => {
//     if (validateForm()) {
//       console.log("Order confirmed:", billingDetails);

//       try {
//         const response = await axios.post(`${process.env.REACT_APP_API_URL}/updatePaymentDetails`, billingDetails, {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         });
//         console.log("response", response);
//         if (response.status === 200) {
//           alert(" successful!");
//         }
//       } catch (error) {
//         alert(error);
//       }
//     }
//   };

//   const { subtotal, subcompany, discount, total } = calculateTotal();

//   return (
//     <div className="payment_outer_div">
//       <div className="payment_inner_div">
//         <div className="header_div">
//           <div className="payment_container_div">
//             <div className="header_div_inner">
//               <div className="header_logo">
//                 <p className="logo_text">Tax-Link</p>
//               </div>
//               <div className="header_icon">
//                 <p>Home</p>
//                 <p>Pricing</p>
//                 <p>Client Area</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Banner */}
//         <div className="header_banner_div">
//           <p>Checkout</p>
//         </div>

//         {/* Body */}
//         <div className="body_div_outer">
//           <div className="payment_container_div">
//             <div className="body_div">
//               <div className="billing_details_div">
//                 <p className="card_title">Billing details</p>
//                 <div className="input_div">
//                   <label>Select Plan</label>
//                   {/* <input name="plan" value={billingDetails.plan} onChange={handleInputChange} placeholder="Select your plan" /> */}
//                   <select name="plan" value={billingDetails.plan} onChange={handleInputChange}>
//                     <option value="">Select your plan</option>
//                     <option value="5000">5000 Rs with 5 companies and 3 users</option>
//                     <option value="10000">10000 Rs with unlimited companies and 3 users</option>
//                   </select>
//                 </div>
//                 <div className="input_div_flax">
//                   <div className="input_div">
//                     <label>Number of Users</label>
//                     <input name="users" value={billingDetails.users} onChange={handleInputChange} placeholder="Enter number of users" type="number" />
//                   </div>
//                   {billingDetails.plan != 10000 && (
//                     <div className="input_div">
//                       <label>Number of Company</label>
//                       <input name="company" value={billingDetails.company} onChange={handleInputChange} placeholder="Enter number of company" type="number" />
//                     </div>
//                   )}
//                 </div>
//                 <div className="input_div">
//                   <label>Number </label>
//                   <input name="number" value={billingDetails.number} onChange={handleInputChange} placeholder="Enter your number" />
//                 </div>
//                 <div className="input_div_flax">
//                   <div className="input_div">
//                     <label>First Name *</label>
//                     <input name="firstName" value={billingDetails.firstName} onChange={handleInputChange} placeholder="Enter your first name" />
//                   </div>
//                   <div className="input_div">
//                     <label>Last Name * </label>
//                     <input name="lastName" value={billingDetails.lastName} onChange={handleInputChange} placeholder="Enter your last name" />
//                   </div>
//                 </div>
//                 <div className="input_div">
//                   <label>Email Address * </label>
//                   <input name="email" value={billingDetails.email} onChange={handleInputChange} placeholder="Enter your email address" type="email" />
//                 </div>
//               </div>

//               {/* Order Summary */}
//               <div className="your_order_div">
//                 <p className="card_title">Your order</p>
//                 <div className="product_div">
//                   <div className="item_div item_dark_div">
//                     <p>Product</p>
//                     <p>Subtotal</p>
//                   </div>

//                   {billingDetails.plan == 5000 && (
//                     <div className="item_div">
//                       <div className="plan_text">
//                         <p className="dark_plan_text">Diamond</p>
//                         <p>5 companies and 3 users</p>
//                       </div>
//                       <p>Rs. {Number(billingDetails.plan).toFixed(2) || 0}</p>
//                     </div>
//                   )}
//                   {billingDetails.plan == 10000 && (
//                     <div className="item_div">
//                       <div className="plan_text">
//                         <p className="dark_plan_text">Platinum</p>
//                         <p>Unlimited companies and 3 users</p>
//                       </div>
//                       <p>Rs. {Number(billingDetails.plan).toFixed(2) || 0}</p>
//                     </div>
//                   )}
//                   {subtotal > 0 && (
//                     <div className="item_div">
//                       <p>Add More {billingDetails.users || 0} User(s)</p>
//                       <p>Rs. {subtotal.toFixed(2) || 0}</p>
//                     </div>
//                   )}
//                   {subcompany > 0 && (
//                     <div className="item_div">
//                       <p>Add More {billingDetails.company || 0} company(s)</p>
//                       <p>Rs. {subcompany.toFixed(2) || 0}</p>
//                     </div>
//                   )}
//                   <div className="item_div">
//                     <p>Discount</p>
//                     <p>Rs. {discount.toFixed(2)}</p>
//                   </div>
//                   <div className="item_div item_dark_div">
//                     <p>Total</p>
//                     <p>Rs. {total.toFixed(2)}</p>
//                   </div>
//                   <div className="item_btn_div">
//                     <input name="discountCode" value={billingDetails.discountCode} onChange={handleInputChange} placeholder="Enter discount code" />
//                     <button onClick={() => alert("Discount applied if valid")}>Apply</button>
//                   </div>
//                 </div>
//                 <span>By clicking on the "Confirm Order" button, you agree to our Terms and Conditions, Privacy Policy, and Refund Policy.</span>
//                 <button className="confirm_order_btn" onClick={handleSubmit}>
//                   Confirm Order
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Footer */}
//         <div className="footer_div">
//           <p>Copyrights © Tax-Link 2024. All rights reserved.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default withProfile(PaymentPage);

import React, { useState } from "react";
import { withProfile } from "../../contexts/profileContext";
import axios from "axios";

const PaymentPage = ({ validatedProfile }) => {
  const [billingDetails, setBillingDetails] = useState({
    plan: "",
    users: 0,
    company: 0,
    number: validatedProfile.mobile,
    firstName: validatedProfile.name.split(" ")[0] || "",
    lastName: validatedProfile.name.split(" ")[1] || "",
    email: validatedProfile.email,
    discountCode: "",
    total: 0,
    userId: validatedProfile.userid,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setBillingDetails((prev) => {
      const updatedDetails = { ...prev, [name]: value };

      if (name === "plan" && value === "10000") {
        updatedDetails.company = 0; // Reset company when plan is 10000
      }

      return updatedDetails;
    });
  };

  const validateForm = () => {
    const { firstName, lastName, email, plan } = billingDetails;
    if (!firstName || !lastName || !email || !plan) {
      alert("Please fill all required fields.");
      return false;
    }
    return true;
  };

  const calculateTotal = () => {
    const planPrice = parseInt(billingDetails.plan, 10);
    const users = parseInt(billingDetails.users, 10);
    const company = billingDetails.plan !== "10000" ? parseInt(billingDetails.company, 10) : 0;

    const subtotal = 500 * users;
    const subcompany = 500 * company;
    const discount = billingDetails.discountCode === "SAVE10" ? ((planPrice + subtotal + subcompany) * 10) / 100 : 0;

    const total = planPrice + subtotal + subcompany - discount;
    billingDetails["total"] = total;
    billingDetails["discount"] = discount;
    return { subtotal, subcompany, discount, total };
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/updatePaymentDetails`, billingDetails, { headers: { "Content-Type": "application/json" } });

        if (response.status === 200) {
          alert("Order successful!");
        }
      } catch (error) {
        alert("An error occurred during payment submission.");
      }
    }
  };

  const { subtotal, subcompany, discount, total } = calculateTotal();

  return (
    <div className="payment_outer_div">
      <div className="payment_inner_div">
        <div className="header_div">
          <div className="payment_container_div">
            <div className="header_div_inner">
              <div className="header_logo">
                <p className="logo_text">Tax-Link</p>
              </div>
              <div className="header_icon">
                <p>Home</p>
                <p>Pricing</p>
                <p>Client Area</p>
              </div>
            </div>
          </div>
        </div>

        {/* Banner */}
        <div className="header_banner_div">
          <p>Checkout</p>
        </div>

        {/* Body */}
        <div className="body_div_outer">
          <div className="payment_container_div">
            <div className="body_div">
              <div className="billing_details_div">
                <p className="card_title">Billing details</p>

                <div className="input_div">
                  <label>Select Plan</label>
                  <select name="plan" value={billingDetails.plan} onChange={handleInputChange}>
                    <option value="">Select your plan</option>
                    <option value="5000">5000 Rs with 5 companies and 3 users</option>
                    <option value="10000">10000 Rs with unlimited companies and 3 users</option>
                  </select>
                </div>

                <div className="input_div_flax">
                  <div className="input_div">
                    <label>Number of Users</label>
                    <input name="users" type="number" value={billingDetails.users} onChange={handleInputChange} placeholder="Enter number of users" />
                  </div>
                  {billingDetails.plan !== "10000" && (
                    <div className="input_div">
                      <label>Number of Companies</label>
                      <input name="company" type="number" value={billingDetails.company} onChange={handleInputChange} placeholder="Enter number of companies" />
                    </div>
                  )}
                </div>

                <div className="input_div">
                  <label>Phone Number</label>
                  <input name="number" value={billingDetails.number} onChange={handleInputChange} placeholder="Enter your phone number" />
                </div>

                <div className="input_div_flax">
                  <div className="input_div">
                    <label>First Name *</label>
                    <input name="firstName" value={billingDetails.firstName} onChange={handleInputChange} placeholder="Enter your first name" />
                  </div>
                  <div className="input_div">
                    <label>Last Name *</label>
                    <input name="lastName" value={billingDetails.lastName} onChange={handleInputChange} placeholder="Enter your last name" />
                  </div>
                </div>

                <div className="input_div">
                  <label>Email Address *</label>
                  <input name="email" type="email" value={billingDetails.email} onChange={handleInputChange} placeholder="Enter your email address" />
                </div>
              </div>

              {/* Order Summary */}
              <div className="your_order_div">
                <p className="card_title">Your order</p>
                <div className="product_div">
                  <div className="item_div item_dark_div">
                    <p>Product</p>
                    <p>Subtotal</p>
                  </div>

                  <div className="item_div">
                    <div className="plan_text">
                      <p className="dark_plan_text">{billingDetails.plan === "5000" ? "Diamond" : "Platinum"}</p>
                      <p>{billingDetails.plan === "5000" ? "5 companies and 3 users" : "Unlimited companies and 3 users"}</p>
                    </div>
                    <p>Rs. {Number(billingDetails.plan).toFixed(2)}</p>
                  </div>

                  {subtotal > 0 && (
                    <div className="item_div">
                      <p>Add More {billingDetails.users} User(s)</p>
                      <p>Rs. {subtotal.toFixed(2)}</p>
                    </div>
                  )}

                  {subcompany > 0 && (
                    <div className="item_div">
                      <p>Add More {billingDetails.company} company(s)</p>
                      <p>Rs. {subcompany.toFixed(2)}</p>
                    </div>
                  )}

                  <div className="item_div">
                    <p>Discount</p>
                    <p>Rs. {discount.toFixed(2)}</p>
                  </div>

                  <div className="item_div item_dark_div">
                    <p>Total</p>
                    <p>Rs. {total.toFixed(2)}</p>
                  </div>

                  <div className="item_btn_div">
                    <input name="discountCode" value={billingDetails.discountCode} onChange={handleInputChange} placeholder="Enter discount code" />
                    <button onClick={() => alert("Discount applied if valid")}>Apply</button>
                  </div>
                </div>

                <span>By clicking on the "Confirm Order" button, you agree to our Terms and Conditions, Privacy Policy, and Refund Policy.</span>

                <button className="confirm_order_btn" onClick={handleSubmit}>
                  Confirm Order
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="footer_div">
          <p>Copyrights © Tax-Link 2024. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default withProfile(PaymentPage);
