import React, { useState } from "react";
// import logolight from "../../assets/images/logo-light.png";
import logolight from "../../assets/images/45x45.png";
import { Link, useLocation } from "react-router-dom";
import { SettingOutlined, MenuOutlined, AppstoreOutlined, PicCenterOutlined, CaretDownFilled, BellOutlined } from "@ant-design/icons";
import ProfileMenu from "./../Header/HeaderIcon/ProfileMenu";

import NotificationDropdown from "./../Header/HeaderIcon/NotificationDropdown";
import Quickcreate from "./HeaderIcon/Quickcreate";
import MyCompany from "./HeaderIcon/MyCompany";
import { find } from "lodash";

const Header = ({ handleButtonClick }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const pathWithoutSlash = currentPath.slice(1);
  const pathnamecapital = pathWithoutSlash.charAt(0).toUpperCase() + pathWithoutSlash.slice(1);

  const [masterPathsToCheckMaster, setMasterPathsToCheckMaster] = useState(["/master", "/ledgermaster", "/item", "/ruelist", "/tc-sales", "/tc-purchase", "/tc-payment", "/tc-receipt", "/tc-contra"]);
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex header-left">
            <div className="navbar-brand-box text-center header-icon-pd">
              <Link to="/" className="logo logo-light">
                <span className="logo-lg">
                  <img className="navbar-brand-box-big-img" src={logolight} alt="logo-light" height="24" />
                  <p className="logo_text">Tax-link</p>
                </span>
                {/* <span className="logo-lg">
                  <img className="navbar-brand-box-big-img" src={logolight} alt="logo-light" height="24" />
                </span> */}
              </Link>
            </div>
            <div className=" sidebar-togal-div header-icon-pd">
              <button onClick={handleButtonClick} type="button" className="sidebar-togal-btn">
                <MenuOutlined className="icon-header m-7" />
              </button>
            </div>
            <div className="pathnamecapital">
              <p>{pathnamecapital}</p>
            </div>

            {masterPathsToCheckMaster.includes(currentPath) ? (
              <div className="header-search-div">
                <form className="app-search">
                  <div className="position-relative">
                    <input type="text" className="form-control" placeholder="Search..." />
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex header-all-   align-items-center">
            <Quickcreate />
            <NotificationDropdown />
            <MyCompany />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
