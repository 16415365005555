import React, { createContext, useState, useEffect } from "react";
import { isEmpty } from "lodash";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import axios from "axios";

const ProfileContext = createContext();

const secretKey = "L3j*8k!2O#dM9wQ4T$7eV6rX0fN1pG@5";

const ProfileProvider = ({ children }) => {
  const [validatedProfile, setValidatedProfile] = useState(() => {
    const encryptedProfile = Cookies.get("validatedProfile");
    if (encryptedProfile) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedProfile, secretKey);
        const decryptedProfile = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedProfile);
      } catch (error) {
        console.error("Error decrypting profile data:", error);
        return null;
      }
    }
    return null;
  });

  useEffect(() => {
    const storeProfile = async () => {
      if (validatedProfile) {
        try {
          const encryptedProfile = CryptoJS.AES.encrypt(JSON.stringify(validatedProfile), secretKey).toString();
          await Cookies.set("validatedProfile", encryptedProfile, {
            expires: 7,
          });
        } catch (error) {
          console.error("Error encrypting profile data:", error);
        }
      }
    };
    storeProfile();
  }, [validatedProfile]);

  const isActive = () => {
    return !isEmpty(validatedProfile);
  };

  const [mainTableData, setMainTableData] = useState();
  const [subTableData, setSubTableData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (validatedProfile?.companyId) {
      fetchData();
    }
  }, [validatedProfile]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await fetchMainTableData();
      await fetchSubTableData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMainTableData = async () => {
    try {
      const { data, status } = await axios.post(`${process.env.REACT_APP_API_URL}/maintabledata`, {
        userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
        companyId: validatedProfile.companyId,
      });

      if (status === 200) {
        const processData = (data) => {
       
          data["sales/excel"] = data["sales"];
          data["item/excel"] = data["item"];
          data["item"] = data["stockitem"];
          data["stockitem"] = [];
          return data;
        };
        const moveddata = processData(data.results);
        setMainTableData(moveddata);
        console.log("mainTableData", moveddata);
      }
    } catch (error) {
      console.error("Error fetching main table data:", error);
    }
  };

  const fetchSubTableData = async () => {
    try {
      const { data, status } = await axios.post(`${process.env.REACT_APP_API_URL}/subtabledata`, {
        userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
        companyId: validatedProfile.companyId,
      });

      if (status === 200) {
        setSubTableData(data.results);
        console.log("subTableData", data.results);
      }
    } catch (error) {
      console.error("Error fetching sub table data:", error);
    }
  };

  return <ProfileContext.Provider value={{ mainTableData, fetchData, fetchMainTableData, isLoading, setIsLoading, subTableData, fetchSubTableData, isActive, validatedProfile, setValidatedProfile }}>{children}</ProfileContext.Provider>;
};

const withProfile = (Child) => (props) => <ProfileContext.Consumer>{(context) => <Child {...props} {...context} />}</ProfileContext.Consumer>;

export { ProfileProvider, withProfile, ProfileContext };
