import React, { useState } from "react";
import { withProfile } from "../../../contexts/profileContext";
import logoDark from "../../../assets/images/45x45.png";
import { GoogleOutlined, LockOutlined } from "@ant-design/icons";
import { validateLicense } from "../../../utils/functions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import bcrypt from "bcryptjs";

import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Spinner from "../../Common/Spinner/Spinner";

const Login = ({ validatedProfile, setValidatedProfile }) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [isSpinner, setIsSpinner] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSpinner(true);
    try {
      const hashedPassword = await hashWithFixedSalt(password);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/signin`,

        {
          identifier: username,
          password: hashedPassword,
          session_type: "WEB",
        }
      );
      // console.log("validatedProfile", validatedProfile);

      if (response.data.google_user === true) {
        setIsSpinner(false);
        alert("You are register with Google ID please login by Google ");
      } else if (response.data.sessionActive === true) {
        setIsSpinner(false);
        var userConfirmed = window.confirm("You are already logged in on another PC or laptop. You can only log in on one device at a time. Do you want to signout in other Devices?");
        if (userConfirmed) {
          try {
            const token = response.data.token;

            const responce = await axios.post(
              `${process.env.REACT_APP_API_URL}/signout`,
              { identifier: username, session_type: "WEB" },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (responce) {
              const hashedPassword = await hashWithFixedSalt(password);

              const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/signin`,

                {
                  identifier: username,
                  password: hashedPassword,
                  session_type: "WEB",
                }
              );

              setValidatedProfile(response.data);
              setIsSpinner(false);
              navigate("/");
            }
          } catch (error) {
            console.log("error", error);
            alert(error.response.data.message);
          }
        }
      } else {
        setValidatedProfile(response.data);
    
        setIsSpinner(false);
        navigate("/");
      }
    } catch (error) {
      console.log("error", error);
      setIsSpinner(false);
      alert(error.response.data.message);
    }
    async function hashWithFixedSalt(password) {
      const fixedSalt = "$2b$10$yourfixedsaltforyourapplication";
      const hashedPassword = await bcrypt.hash(password, fixedSalt);

      return hashedPassword;
    }
  };

  const handleGoogleLoginSuccess = async (googleUser) => {
    setIsSpinner(true);
    const credentialResponseDecoded = jwtDecode(googleUser.credential);
    const googleId = credentialResponseDecoded.sub;
    const email = credentialResponseDecoded.email;
    const name = credentialResponseDecoded.name;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
        email: email,
        name: name,
        googleId: googleId,
        session_type: "WEB",
      });

      if (response.data.identifier_user === true) {
        setIsSpinner(false);
        alert("You are register with  Email/Mobile and password please login by Email/Mobile");
      } else if (response.data.sessionActive === true) {
        setIsSpinner(false);
        var userConfirmed = window.confirm("You are already logged in on another PC or laptop. You can only log in on one device at a time. Do you want to signout in other Devices?");
        if (userConfirmed) {
          try {
            const token = response.data.token;

            const responce = await axios.post(
              `${process.env.REACT_APP_API_URL}/signout`,
              { identifier: googleId, session_type: "WEB" },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (responce) {
              const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
                email: email,
                name: name,
                googleId: googleId,
                session_type: "WEB",
              });

              setValidatedProfile(response.data);
              setIsSpinner(false);
              navigate("/");
            }
          } catch (error) {
            console.log("error", error);
            alert(error.response.data.message);
          }
        }
      } else {
        setValidatedProfile(response.data);
        setIsSpinner(false);
        navigate("/");
      }
    } catch (error) {
      console.log("error", error);
      setIsSpinner(false);
      alert(error.response.data.message);
    }
  };

  const responseGoogleFailure = (response) => {
    console.log("Google Login Failure:", response);
  };

  return (
    <>
      <Spinner isSpinner={isSpinner} />
      <div className="bg-overlay"></div>
      <div className="auth-bg">
        <div className="auth-page">
          <div>
            <div className="titel">
              <img src={logoDark} alt="logo" />
              <p className="titel_text">Tax-link</p>
            </div>
            <h4 className="text-muted mt-2" style={{ fontSize: 18 }}>
              WelCome Back
            </h4>
            <span className="text-muted">Sign in to continue to Tax-link.</span>
          </div>
          <div className="text-left mt-3">
            <form action="#" onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="Mobile/Email">
                  Mobile/Email<b className="text-danger">*</b>
                </label>
                <input type="text" className="form-control" id="Mobile/Email" placeholder="Enter Mobile/Email" required onChange={(e) => setUsername(e.target.value)} />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="userpassword">
                  Password<b className="text-danger">*</b>
                </label>
                <input type="password" className="form-control" id="userpassword" placeholder="Enter password" required onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="d-flex justify-content-between ">
                <a className="text-muted" href="/signIn-with-otp">
                  Sign in with OTP
                </a>
                <a className="text-muted" href="/forgot-password">
                  Forgot your password ?
                </a>
              </div>
              <div className="mt-3">
                <button className="btn btn-primary w-100" type="submit">
                  Log In
                </button>
              </div>
            </form>
          </div>
          <div className="GoogleLogin_div">
            <span>or</span>
            <GoogleLogin
              className="btn mt-2 d-flex align-items-center justify-content-center"
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Login  with Google"
              onSuccess={handleGoogleLoginSuccess}
              onFailure={responseGoogleFailure}
              cookiePolicy={"single_host_origin"}
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            />
          </div>
          <div className="text-center mt-3">
            <span className="text-muted">
              Don't have an account ?{" "}
              <a className="text-primary" href="/register">
                {" "}
                Register{" "}
              </a>{" "}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default withProfile(Login);
