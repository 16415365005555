import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, DatePicker, Modal, Select, Table } from "antd";
import { YoutubeOutlined, ArrowLeftOutlined, DoubleLeftOutlined, SnippetsOutlined, InfoCircleOutlined } from "@ant-design/icons";

import AgGridSubTable from "../../../../Layout/SubTable/AgGridTable";
import { withProfile } from "../../../../contexts/profileContext";
import { withRole } from "../../../../contexts/roleContext";
import Spinner from "../../../Common/Spinner/Spinner";
import TallySyncTable from "../../../../Layout/SubTable/TallySyncPopUpBox/TallySyncTable";

const AutoExpensesTransactionsTable = ({ validatedProfile, subTableData, fetchSubTableData, role }) => {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // New state for spinner
  const [tallySyncModalOpen, setTallySyncModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // To store selected row keys
  const [selectedTallySyncId, setSelectedTallySyncId] = useState([]); // for data of TallySync id

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const saveButtonNotPermition = ["autosale"];
  const handleDateChange = (dates) => {
    if (dates) {
      setStartDate(dates[0]?.format("YYYY-MM-DD"));
      setEndDate(dates[1]?.format("YYYY-MM-DD"));
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const navigate = useNavigate();

  const location = useLocation();
  // const currentPath = location.pathname;
  let { key, pathWithoutSlash } = location.state || {};
  const pathnamecapital = pathWithoutSlash.charAt(0).toUpperCase() + pathWithoutSlash.slice(1);

  const gobackpage = () => {
    navigate("/" + `${pathWithoutSlash}`);
  };

  let path;
  switch (pathWithoutSlash) {
    case "autoexpenses":
      path = "autoexpensesdata";
      break;
    default:
      path = `${pathWithoutSlash}data`;
      break;
  }

  useEffect(() => {
    viewAll();
  }, [subTableData]);

  const viewAll = async () => {
    setIsLoading(true);

    try {
      if (subTableData) {
        let Data = subTableData[path];
        Data = Data.filter((row) => {
          return row.srno === key;
        });
     
        if (path === "autoexpensesdata") {
          const parsedExpenses = JSON.parse(Data[0].expenses_name);

          Data = parsedExpenses.map(({ "Sr No": _, ...rest }) => ({
            ...rest,
          }));
        }

        Data = Data.map((obj, index) => ({
          "Sr No": index + 1,
          ...Object.fromEntries(Object.entries(obj).map(([key, value]) => [key.replace(/_/g, " "), value])),
        }));
        console.log("Data af", Data);

        setRowData(Data);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const moreInfoBtn = () => {
    setTallySyncModalOpen(!tallySyncModalOpen);
  };

  const handleOpenAutoExpensesTallySyncTable = () => {
    let dataForSendToTally = [];
    setIsLoading(true);

    if (selectedRowKeys.length > 0) {
      selectedRowKeys.forEach((rowId) => {
        const selectedRow = rowData.find((row) => row.id === rowId.id && row.flag === "NS");
        if (selectedRow) dataForSendToTally.push(selectedRow);
      });

     
      if (dataForSendToTally.length === 0) {
        alert("All selected rows are already synced.");
        return;
      }

      Modal.confirm({
        title: "Are you sure you want to push these entries to Tally?",
        okText: "Yes",
        cancelText: "Cancel",
        async onOk() {
          rowData.forEach((row) =>
            dataForSendToTally.forEach((rowid) => {
              if (row.id === rowid.id) {
                row.flag = "PS";
              }
            })
          );

          // console.log("Row Data after processing: ", rowData);

          let payload = { userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid, companyId: validatedProfile.companyId, updateId: key, data: rowData, flag: "PS" };
          // console.log("payload", payload);
          axios
            .post(`${process.env.REACT_APP_API_URL}/update-autoexpenses`, payload)
            .then((response) => {
              if (response.status === 200) {
                fetchSubTableData();
                viewAll();
                setSelectedRowKeys([]);
                setSelectedTallySyncId(dataForSendToTally);
                setTallySyncModalOpen(true);
                setIsLoading(false);
              }
            })
            .catch((error) => {
              setIsLoading(false);
              console.error("Error:", error);
              setSelectedRowKeys([]);
            });

          setSelectedRowKeys([]);
        },
        onCancel() {
          console.log("Operation canceled by the user.");
        },
      });
    } else {
      alert("Please select at least one row to push data to Tally.");
    }
  };

  const DeleteMultipleSelectedRow = () => {
    setIsLoading(true);
    let payload = { userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid, companyId: validatedProfile.companyId, tablename: path, deleteID: selectedRowKeys };

    axios
      .post(`${process.env.REACT_APP_API_URL}/deleteData`, payload)
      .then((response) => {
        if (response.status === 200) {
          fetchSubTableData();
          viewAll();
          setSelectedRowKeys([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
        setSelectedRowKeys([]);
      });
  };

  const [checkboxes, setCheckboxes] = useState({
    hideRecords: false,
    savedRecords: false,
    blankRecords: false,
    failedRecords: false,
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: checked,
    }));
  };

  return (
    <>
      {isLoading && <Spinner isSpinner={isLoading} />}
      <div className="TransactionsTable">
        <div className="TransactionsTable-heading">
          <div className="table-title">
            <Button onClick={gobackpage}>
              <ArrowLeftOutlined />
            </Button>
            <p className="subtable-title-name">
              <SnippetsOutlined /> {pathnamecapital}
            </p>
            <span className="TransactionsNumber ">{rowData.length}</span>
          </div>
          <div className="table-company-title">
            <span>Company Name:</span>
            <p>{validatedProfile.companyName && validatedProfile.companyName.toUpperCase()}</p>
          </div>
        </div>
        <div className="TransactionsTable-top-heading">
          <div className="operations">
            <div className="operations-inner">
              <p>Bulk Operations </p>
              <small>
                <InfoCircleOutlined />
              </small>
            </div>
            <span>Update Bulk Records</span>
            <input type="file" name="" id="" />
          </div>
          <div className="filters">
            <p>
              General Filters{" "}
              <small>
                <InfoCircleOutlined />
              </small>
            </p>

            <div className="filters-checkbox">
              <div className="checkbox">
                <input type="checkbox" name="hideRecords" id="hide-records" checked={checkboxes.hideRecords} onChange={handleCheckboxChange} />
                <label htmlFor="hide-records">Hide Tally Synced Records</label>
              </div>
              <div className="checkbox">
                <input type="checkbox" name="savedRecords" id="saved-records" checked={checkboxes.savedRecords} onChange={handleCheckboxChange} />
                <label htmlFor="saved-records">Saved Records</label>
              </div>
              <div className="checkbox">
                <input type="checkbox" name="blankRecords" id="blank-records" checked={checkboxes.blankRecords} onChange={handleCheckboxChange} />
                <label htmlFor="blank-records">Blank Records</label>
              </div>
              <div className="checkbox">
                <input type="checkbox" name="failedRecords" id="failed-records" checked={checkboxes.failedRecords} onChange={handleCheckboxChange} />
                <label htmlFor="failed-records">Failed Records</label>
              </div>
            </div>
            <div className="filters-date">
              <div className="date-filter">
                <DatePicker.RangePicker onChange={handleDateChange} />
              </div>
            </div>
          </div>
          <div className="controal-btn">
            <div className="controal-btn-line">
              <div className="moreinfo-btn-menu all-btn">
                <Button onClick={moreInfoBtn}>
                  <InfoCircleOutlined />
                  More info
                </Button>
              </div>

              <div className="create-btn-menu all-btn">
                <Button>
                  <InfoCircleOutlined />
                  Create Ledger
                </Button>
              </div>
              {!saveButtonNotPermition.includes(pathWithoutSlash) && (
                <div className=" all-btn">
                  <Button type="primary">Save</Button>
                </div>
              )}
            </div>

            <div className="controal-btn-line">
              <div className="save-btn-menu all-btn">
                <Button onClick={handleOpenAutoExpensesTallySyncTable}>Send to Tally</Button>
              </div>

              <div className="all-btn">
                <button className="bulkupload-topbar-btn bulkupload-btn btn-outline-danger bg-btn all-btn">
                  <YoutubeOutlined className="topobar-icon text-danger " />
                </button>
              </div>
            </div>
            <Button type="primary TransactionsTable-sidemeny-btn">
              <DoubleLeftOutlined />{" "}
            </Button>
          </div>
        </div>
        <AgGridSubTable role={role} oldPathWithoutSlash={pathWithoutSlash} RowKey={key} path={path} viewAll={viewAll} rowData={rowData} selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} startDate={startDate} endDate={endDate} />

        {selectedRowKeys.length > 1 && role && ["D", "F"].includes(role["/" + `${pathWithoutSlash}`]) ? (
          <Button type="primary" className="TransactionsTable-delete-btn" onClick={DeleteMultipleSelectedRow}>
            Delete Row
          </Button>
        ) : (
          <Button type="primary" className="TransactionsTable-delete-btn" onClick={DeleteMultipleSelectedRow} disabled>
            Delete Row
          </Button>
        )}
      </div>

      {tallySyncModalOpen ? <TallySyncTable tallySyncModalOpen={tallySyncModalOpen} validatedProfile={validatedProfile} setTallySyncModalOpen={setTallySyncModalOpen} pathPlusData={path} selectedTallySyncId={selectedTallySyncId} RowKey={key} /> : ""}
    </>
  );
};

export default withProfile(withRole(AutoExpensesTransactionsTable));
