import React, { useEffect, useState } from "react";
import axios from "axios";
import { withProfile } from "../../../../contexts/profileContext";
import { Table, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const RoleManagement = ({ validatedProfile }) => {
  const [fullAccessData, setFullAccessData] = useState([]);
  const [mainTableData, setMainTableData] = useState([]);
  // console.log("mainTableData",mainTableData);
  const navigate = useNavigate();
  useEffect(() => {
    viewall();
    getRoledata();
  }, []);

  const viewall = async () => {
    const id = {
      parentUserId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/role-management-details`, id); // for get user of perticular role
      const data = response.data.userDetails;
      // console.log("data",data);
      setFullAccessData(data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getRoledata = async () => {
    
    const id = {
      parentUserId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getRole`, id);
      const data = response.data.userDetails;
      setMainTableData(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const editRole = (record) => {
    console.log("Editrole", record);
    navigate("/addrole", {
      state: { editkey: record.srno },
    });
  };

  const deleteRole = (record, key) => {
    // console.log("Deleterole", record, key);
    const isConfirmed = window.confirm("Are you sure you want to delete this role?");
    if (!isConfirmed) {
      return;
    }
    let payload = { userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid, companyId: validatedProfile.companyId, tablename: "role_master", deleteID: [record.srno] };

    axios
      .post(`${process.env.REACT_APP_API_URL}/deleteData`, payload)
      .then((response) => {
        if (response.status === 200) {
          viewall();
          getRoledata();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const columnsmain = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "No. of user",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <p>
          <Button type="link" onClick={() => editRole(record)}>
            <EditOutlined />
          </Button>
          <Button type="link" onClick={() => deleteRole(record)}>
            <DeleteOutlined className="text-danger" />
          </Button>
        </p>
      ),
    },
  ];

  const data = [];
  mainTableData.forEach((item, index) => {
    const relatedUsers = fullAccessData.filter((user) => item.srno === user.role_srno);

    data.push({
      key: index,
      srno: index + 1,
      name: item.role_name,
      srno: item.srno,
      type: "User",
      user: relatedUsers.length,
    });
  });

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Sr. No.",
        dataIndex: "key",
        key: "key",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        key: "mobile",
      },
    ];

    const datasub = fullAccessData
      .filter((user) => record.srno === user.role_srno)
      .map((user, index) => ({
        key: (index + 1).toString(),
        name: user.user_name,
        email: user.user_email,
        mobile: user.user_mobile,
      }));

    return <Table columns={columns} dataSource={datasub} pagination={false} />;
  };

  const [expandedKeys, setExpandedKeys] = useState([]);
  const handleRowClick = (record, rowIndex) => {
    const key = record.key;
    const newExpandedKeys = expandedKeys.includes(key) ? expandedKeys.filter((k) => k !== key) : [...expandedKeys, key];

    setExpandedKeys(newExpandedKeys);
  };

  const handleExpand = (expanded, record) => {
    const key = record.key;
    const newExpandedKeys = expanded ? [...expandedKeys, key] : expandedKeys.filter((k) => k !== key);

    setExpandedKeys(newExpandedKeys);
  };

  return (
    <div className="page-content">
      <div className="Role_Management_Div">
        <div className="header d-flex my-2 mr-2 justify-content-end">
          <button className="rounded text-light btn-primary border-primary py-2 px-2">
            <Link className="text-light" to={"/addrole"}>
              Create New Role
            </Link>
          </button>
        </div>
        <Table
          className="Role_Management_Table"
          columns={columnsmain}
          expandable={{
            expandedRowRender,
            expandedRowKeys: expandedKeys,
            onExpand: handleExpand,
          }}
          onRow={(record, rowIndex) => ({
            onClick: () => handleRowClick(record, rowIndex),
          })}
          dataSource={data}
          rowKey="key"
          pagination={false}
          scroll={{ y: "65vh" }}
        />
      </div>
    </div>
  );
};

export default withProfile(RoleManagement);
