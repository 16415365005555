import React, { useState } from "react";
import user1 from "../../../assets/images/users/avatar-9.jpg";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Tooltip } from "antd";
import { UserOutlined, WalletOutlined, SettingOutlined, LockOutlined, CaretDownFilled, LogoutOutlined } from "@ant-design/icons";
import axios from "axios";
import { Menu } from "antd";
import { withProfile } from "../../../contexts/profileContext";

const ProfileMenu = ({ validatedProfile }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleMenuClick = (e) => {
    setDropdownVisible(false);
  };
  const hendleclick = async (e) => {
    let userConfirmed = window.confirm("Do you want to signout?");

    if (userConfirmed && validatedProfile) {
      try {
        const responce = await axios.post(
          `${process.env.REACT_APP_API_URL}/signout`,
          { identifier: validatedProfile.email, session_type: "WEB" },
          {
            headers: {
              Authorization: `Bearer ${validatedProfile.token}`,
            },
          }
        );
        if (responce) {
          localStorage.removeItem("role");
          localStorage.removeItem("roleWithoutSlash");
          navigate("/login");
          Cookies.remove("validatedProfile");
        }
      } catch (error) {
        console.log("error", error);
        // alert(error.response.data.message);
      }
    } else {
      console.log("User canceled logout.");
    }
  };
  const menu = (
    <div className="header-sub-menu sub-menu-lng">
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="item1">
          <Link to="/my-profile">
            <UserOutlined className="header-sub-menu-profile-icon" />
            Profile
          </Link>
        </Menu.Item>
        <Menu.Item key="item2">
          <Link to="/dashboard">
            <WalletOutlined className="header-sub-menu-profile-icon" />
            My wallet
          </Link>
        </Menu.Item>
        <Menu.Item key="item3">
          <Link to="/dashboard">
            <SettingOutlined className="header-sub-menu-profile-icon" />
            Settings
          </Link>
        </Menu.Item>
        <Menu.Item key="item4">
          <Link to="/dashboard">
            <LockOutlined className="header-sub-menu-profile-icon" />
            Lock screen
          </Link>
        </Menu.Item>
        <Menu.Item key="item5">
          <Link onClick={hendleclick}>
            <LogoutOutlined className="header-sub-menu-profile-icon" />
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <Dropdown overlay={menu} open={dropdownVisible} onOpenChange={(visible) => setDropdownVisible(visible)} trigger={["click"]}>
      <Tooltip
        placement="bottomRight"
        title={
          <span>
            {validatedProfile?.name}
            <br />
            {validatedProfile?.email}
          </span>
        }
      >
        <span onClick={handleButtonClick} className="header-profile header-icon-pd">
          <img src={user1} className="header-admin-img " alt="" /> <span className="header-admin-name">{validatedProfile?.name?.split(" ")[0].toUpperCase() || "Hello"}</span>
          <CaretDownFilled className="header-admin-icon w-5" />
        </span>
      </Tooltip>
    </Dropdown>
  );
};

export default withProfile(ProfileMenu);
