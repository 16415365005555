import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import withRouter from "../components/Common/withRouter";

import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import SidebarSmall from "./Sidebar/SidebarSmall";
import HeaderSmall from "./Header/HeaderSmall";
import TopBar from "./TopBar/TopBar";
import ChatButton from "../constants/ChatButton";
import TransactionsTable from "./SubTable/TransactionsTable";
import Mapping from "./Mapping/Mapping";
import AutoExpensesTransactionsTable from "../components/Pages/AutoExpenses/AutoExpensesTransactionsTable";
import PaymentPage from "../components/Payment";

function shouldRenderTopBar(currentPath) {
  return !["/dashboard", "/analysis", "/reports", "/gst-record", "/document", "/learn"].includes(currentPath);
}

const Layout = (props) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleButtonClick = () => {
    setIsButtonClicked(!isButtonClicked);
  };

  const renderComponent = () => {
    switch (currentPath) {
      case "/transactionstable":
        return <TransactionsTable />;
      case "/autoExpensesTransactionsTable":
        return <AutoExpensesTransactionsTable />;
      case "/mapping":
        return <Mapping />;
      case "/payment":
        return <PaymentPage />;
      default:
        return null;
    }
  };

  const isSpecialRoute = ["/transactionstable", "/autoExpensesTransactionsTable", "/mapping", "/payment"].includes(currentPath);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        {isSpecialRoute ? (
          renderComponent()
        ) : (
          <>
            {isButtonClicked ? (
              <>
                <Header handleButtonClick={handleButtonClick} />
                <Sidebar />
                <div className="main-content">
                  {shouldRenderTopBar(currentPath) && <TopBar />}
                  {props.children}
                </div>
              </>
            ) : (
              <>
                <HeaderSmall handleButtonClick={handleButtonClick} />
                <SidebarSmall />
                <div className="main-content-small">
                  {shouldRenderTopBar(currentPath) && <TopBar />}
                  {props.children}
                </div>
              </>
            )}
          </>
        )}
        {!isSpecialRoute && (
          <div className="chatbutton">
            <ChatButton />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default withRouter(Layout);
