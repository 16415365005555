import React from "react";
import { Navigate } from "react-router-dom";
import { withProfile } from "../contexts/profileContext";
import { useEffect } from "react";

const AuthProtected = ({ children, location, isActive }) => {
  if (!isActive()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default withProfile(AuthProtected);
