import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal, DatePicker } from "antd";
import { DeleteOutlined, MenuOutlined, PlusCircleOutlined, DownOutlined, UpOutlined, UpSquareOutlined, DownSquareOutlined } from "@ant-design/icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-enterprise";

import { withProfile } from "../../../contexts/profileContext";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import { useCallback } from "react";
import { AgGridReact } from "ag-grid-react";

const { confirm } = Modal;

dayjs.extend(customParseFormat);
dayjs.extend(isBetween); // Extend dayjs with isBetween plugin
const { RangePicker } = DatePicker;

const typelist = ["Payment", "Receipt", "Contra Deposit", "Contra Withdraw"];

const AgGridSubTable = ({
  role,
  oldPathWithoutSlash,
  validatedProfile,
  fetchSubTableData,
  RowKey,
  path,
  setUpadateLedger,
  viewAll,
  ledgeroption,
  ledgerUnderSalesAccounts,
  stockitemNameOptions,
  partyAcNameOptions,
  rowData,
  selectedRowKeys,
  setSelectedRowKeys,
  startDate,
  endDate,
  setLedgerNameError,
  setStockitemNameError,
  selectedSlpitIdData,
  handleOpenSlpitMenu,
}) => {
  const [colDefs, setcolDefs] = useState([]);

  const location = useLocation();
  let { key, pathWithoutSlash, pathname, pathWithonlyname } = location.state || {};
  const pathnamecapital = pathWithoutSlash.charAt(0).toUpperCase() + pathWithoutSlash.slice(1);

  // console.log("pathWithoutSlash", pathWithoutSlash);
  let pathPlusData;
  switch (pathWithoutSlash) {
    case "sales/excel":
      pathPlusData = "salesdata";
      break;
    case "item/excel":
      pathPlusData = "itemdata";
      break;
    case "ledgerbulk":
      pathPlusData = "ledgerbulkdata";
      break;
    default:
      pathPlusData = pathWithoutSlash + "data";
      break;
  }

  useEffect(() => {
    if (pathWithoutSlash === "sales/excel") {
      let stockitemNameErrorStatus = rowData.filter((item) => stockitemNameOptions && !stockitemNameOptions.includes(item["name of item"]));
      let ledgerNameErrorStatus = rowData.filter((item) => ledgeroption && !ledgeroption.includes(item["party ac name"]));

      setStockitemNameError(stockitemNameErrorStatus);
      setLedgerNameError(ledgerNameErrorStatus);
    }
  }, [rowData]);

  useEffect(() => {
    setcolDefs(generateColumns(rowData));
  }, [rowData]);

  useEffect(() => {
    if (stockitemNameOptions || ledgeroption) {
      setcolDefs(generateColumns(rowData));
    }
  }, [ledgeroption, stockitemNameOptions]);

  // ================table  start ==============

  const getRowStyle = (params) => {
    if (params.data.flag === "SY") {
      return { background: "#E9ECEF" };
    }
  };

  const isCellEditable = useCallback((params) => {
    return params.data.flag !== "SY"; // disable editing for cells in rows with flag "SY"
  }, []);

  const gridOptions = {
    rowSelection: "multiple",
    isCellEditable,

    defaultColDef: {
      filterList: true,
      suppressMenu: true,
      sortable: true,
    },

    onSelectionChanged: (event) => {
      let selectedNodes = event.api.getSelectedNodes();
      const selectedIndexes = selectedNodes.map((node) => ({
        id: node.data.id,
      }));
      console.log("selectedIndexes", selectedIndexes);

      setSelectedRowKeys(selectedIndexes);
    },
  };

  const generateColumns = (input) => {
    if (!input || !input.length) return [];

    const columnConfig = {
      "name of item": {
        headerName: "Name of Item",
        field: "name of item",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        // headerComponent: CustomHeader,
        editable: (params) => params.data.flag !== "SY",
        cellEditor: "agRichSelectCellEditor",
        cellEditorPopup: false,
        cellRenderer: (params) => {
          const isError = stockitemNameOptions && !stockitemNameOptions.includes(params.value);
          return (
            <div className={`d-flex justify-content-between ${isError ? "text-danger" : "text-primary"}`}>
              <span>{params.value || "Select Item"}</span>
              <span>
                <DownOutlined />
              </span>
            </div>
          );
        },
        cellEditorParams: {
          values: stockitemNameOptions,
          searchType: "match",
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
        },
        onCellValueChanged: (params) => {
          const selectedValue = params.data["name of item"];
          setUpadateLedger((updateLedger) => [...updateLedger, { id: params.data.id, name_of_item: selectedValue, srno: params.data.srno }]);
          const nextRowIndex = params.node.rowIndex + 1;
          const rowCount = params.api.getDisplayedRowCount();

          if (nextRowIndex < rowCount) {
            params.api.setFocusedCell(nextRowIndex, "name of item");
          }
        },
      },
      "party ac name": {
        headerName: "Party Ac Name",
        field: "party ac name",
        filter: "agTextColumnFilter",
        floatingFilter: true,

        editable: (params) => params.data.flag !== "SY",
        cellEditor: "agRichSelectCellEditor",
        cellEditorPopup: false,
        cellRenderer: (params) => {
          const isError = ledgeroption && !ledgeroption.includes(params.value);
          return (
            <div className={`d-flex justify-content-between ${isError ? "text-danger" : "text-primary"}`}>
              <span>{params.value || "Select Party"}</span>
              <span>
                <DownOutlined />
              </span>
            </div>
          );
        },
        cellEditorParams: {
          values: ledgeroption,
          searchType: "match",
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
        },
        onCellValueChanged: (params) => {
          const selectedValue = params.data["party ac name"];
          setUpadateLedger((updateLedger) => [...updateLedger, { id: params.data.id, party_ac_name: selectedValue, srno: params.data.srno }]);
          const nextRowIndex = params.node.rowIndex + 1;
          const rowCount = params.api.getDisplayedRowCount();

          if (nextRowIndex < rowCount) {
            params.api.setFocusedCell(nextRowIndex, "party ac name");
          }
        },
      },
      ledger: {
        headerName: "Ledger",
        field: "ledger",
        filter: "agTextColumnFilter",
        floatingFilter: true,

        editable: (params) => params.data.flag !== "SY",
        cellEditor: "agRichSelectCellEditor",
        cellEditorPopup: false,
        cellRenderer: (params) => (
          <div className={`d-flex justify-content-between ${params.value ? "text-primary" : "text-secondary"}`}>
            <span>{params.value || "Select Ledger"}</span>
            <span>
              <DownOutlined />
            </span>
          </div>
        ),
        cellEditorParams: {
          values: ledgeroption,
          searchType: "match",
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
        },

        onCellValueChanged: (params) => {
          const selectedValue = params.data.ledger;
          setUpadateLedger((updateLedger) => [...updateLedger, { id: params.data.id, ledger: selectedValue, srno: params.data.srno }]);
          const nextRowIndex = params.node.rowIndex + 1;
          const rowCount = params.api.getDisplayedRowCount();

          if (nextRowIndex < rowCount) {
            params.api.setFocusedCell(nextRowIndex, "ledger");
          }
        },
      },
      "sales ledger": {
        headerName: "Sales Ledger",
        field: "sales ledger",
        filter: "agTextColumnFilter",
        floatingFilter: true,

        editable: (params) => params.data.flag !== "SY",
        cellEditor: "agRichSelectCellEditor",
        cellEditorPopup: false,
        cellRenderer: (params) => (
          <div className={`d-flex justify-content-between ${params.value ? "text-primary" : "text-secondary"}`}>
            <span>{params.value || "Select Sales Ledger"}</span>
            <span>
              <DownOutlined />
            </span>
          </div>
        ),
        cellEditorParams: {
          values: ledgerUnderSalesAccounts,
          searchType: "match",
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
        },

        onCellValueChanged: (params) => {
          // console.log("params", params);
          const selectedValue = params.data["sales ledger"];
          setUpadateLedger((updateLedger) => [...updateLedger, { id: params.data.id, sales_ledger: selectedValue, srno: params.data.srno }]);
          const nextRowIndex = params.node.rowIndex + 1;
          const rowCount = params.api.getDisplayedRowCount();

          if (nextRowIndex < rowCount) {
            params.api.setFocusedCell(nextRowIndex, "sales ledger");
          }
        },
      },
      vchtype: {
        headerName: "Type",
        field: "vchtype",
        filter: "agTextColumnFilter",
        floatingFilter: true,

        editable: (params) => params.data.flag !== "SY",
        cellEditor: "agRichSelectCellEditor",

        cellRenderer: (params) => (
          <div className={`d-flex justify-content-between ${params.value ? "text-primary" : "text-secondary"}`}>
            <span>{params.value || "Select Type"}</span>
            <span>
              <DownOutlined />
            </span>
          </div>
        ),
        cellEditorParams: {
          values: typelist,
          searchType: "match",
          allowTyping: true,
          filterList: true,
          highlightMatch: true,
        },
        onCellValueChanged: (params) => {
          const selectedValue = params.data.vchtype;
          setUpadateLedger((updateLedger) => [...updateLedger, { id: params.data.id, type: selectedValue, srno: params.data.srno }]);
          const nextRowIndex = params.node.rowIndex + 1;
          const rowCount = params.api.getDisplayedRowCount();

          if (nextRowIndex < rowCount) {
            params.api.setFocusedCell(nextRowIndex, "vchtype");
          }
        },
      },
      "sr no": {
        headerName: "Sr No",
        field: "Sr No",
        suppressFilter: true,
        headerCheckboxSelection: true,

        menuTabs: [],
        checkboxSelection: true,
        showDisabledCheckboxes: true,
      },
      name: {
        headerName: "Name",
        field: "name",
        menuTabs: [],
        tooltipValueGetter: (p) => p.value,
        tooltipShowDelay: 0,
      },
      amount: {
        headerName: "Amount",
        field: "amount",
        filter: "agTextColumnFilter",
        floatingFilter: true,

        menuTabs: [],
        cellStyle: { textAlign: "right", letterSpacing: "0.05em" },
        // valueFormatter: (params) => {
        //   console.log("params", params.data);
        //   return params.value ? params.value.toFixed(2) : "0.00";
        // },
        cellRenderer: (params) => (
          <div className={`d-flex justify-content-end ${params.data.debit > 0 ? "text-danger" : "text-success"}`}>
            <span>{params.value.toFixed(2) || "0.00"}</span>
          </div>
        ),
      },
      rate: {
        headerName: "Rate",
        field: "rate",
        menuTabs: [],
        cellStyle: { textAlign: "right", letterSpacing: "0.05em" },
      },

      "total amount": {
        headerName: "Total",
        field: "total amount",
        menuTabs: [],
        cellStyle: { textAlign: "right", letterSpacing: "0.05em" },
        valueFormatter: (params) => {
          return params.value ? params.value.toFixed(2) : "0.00";
        },
      },
      description: {
        headerName: "Description",
        field: "description",
        filter: "agTextColumnFilter",
        floatingFilter: true,

        menuTabs: [],
        wrapText: true,
        autoHeight: true,
        tooltipValueGetter: (p) => p.value,
        tooltipShowDelay: 0,
      },
    };

    const flexRules = (field) => {
      if (pathWithoutSlash === "banking") {
        if (["Sr No"].includes(field)) return 0.5;
        if (["date"].includes(field)) return 0.5;
        if (["description"].includes(field)) return 2;
        if (["ledger"].includes(field)) return 3;
      } else if (pathWithoutSlash === "sales/excel") {
        // console.log("field", field);
        if (["Sr No"].includes(field)) return 0.7;
        if (["invoice date"].includes(field)) return 0.7;
        if (["referance no"].includes(field)) return 0.5;
        if (["party ac name", "name of item"].includes(field)) return 2.5;
        if (["sgst", "cgst", "igst", "quantity"].includes(field)) return 0.35;
        if (["rate", "amount", "total amount"].includes(field)) return 0.6;
      }

      return 1;
    };

    const unwantedColumns = {
      banking: ["srno", "flag", "id", "company master id", "user master id", "created by", "created on", "modified by", "modified on", "synced date", "cheque no", "primaryentry", "credit", "debit", "issplit", "balance"],
      sales: ["srno", "flag", "id", "company master id", "user master id", "created by", "created on", "modified by", "modified on", "synced date", "gst no", "particulars"],
    };

    const unwantedCols = pathWithoutSlash === "banking" ? unwantedColumns.banking : unwantedColumns.sales;
    let columns = Object.keys(input[0]).map((field) => {
      const formattedField = field.replace(/_/g, " ").toLowerCase();
      const upperCaseField = field.replace(/\b\w/g, (char) => char.toUpperCase());

      const flex = flexRules(field);

      return columnConfig[formattedField] ? { ...columnConfig[formattedField], flex, sortable: true } : { headerName: upperCaseField, field, flex, sortable: true };
    });

    columns = columns.filter((column) => !unwantedCols.includes(column.field.replace(/_/g, " ").toLowerCase()));

    columns.push({
      headerName: "Action",
      field: "action",
      flex: 0.5,
      cellRenderer: (params) => {
        const { data } = params;
        const { flag, id } = data;
        const isDangerRole = role["/" + oldPathWithoutSlash] ? role && ["D", "F"].includes(role["/" + oldPathWithoutSlash]) : true;
        const isFlagSY = flag === "SY";
        const deleteButtonClass = `TransactionsTable-deleterow Table-deleterow ${isDangerRole && !isFlagSY ? "text-danger" : "text-secondary"} ${isFlagSY ? "text-secondary" : ""}`;

        const handleDeleteClick = (event) => {
          if (!isFlagSY) {
            event.stopPropagation();
            deleteSingleRow(data);
          }
        };

        return (
          <p className="action_btn_Div">
            <Button className={deleteButtonClass} type="danger" onClick={handleDeleteClick} disabled={role && !isDangerRole}>
              <DeleteOutlined />
            </Button>
            {pathnamecapital === "Banking" && !isFlagSY && <Button className="bg-transparent splitOpen-btn" type="text" icon={<PlusCircleOutlined />} onClick={() => handleOpenSlpitMenu(id)} />}
          </p>
        );
      },
    });

    return columns;
  };

  const isRowSelectable = useCallback((params) => {
    return !!params.data && params.data.flag !== "SY";
  }, []);

  const deleteSingleRow = (rowData) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/deleteData`, {
        userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
        companyId: validatedProfile.companyId,
        tablename: pathPlusData,
        deleteID: [{ id: rowData.id }],
      })
      .then((response) => {
        if (response.status === 200) {
          fetchSubTableData();
          viewAll();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div className="TransactionsTable-table">
        <div className="ag-theme-balham ag-TransactionsTable" style={{ height: "calc(100vh - 220px)", fontSize: "12px" }}>
          <AgGridReact className="transactions_table_ag" gridOptions={gridOptions} isRowSelectable={isRowSelectable} columnDefs={colDefs} getRowStyle={getRowStyle} rowData={rowData} suppressRowClickSelection={true} rowSelection="multiple" floatingFilter={false} />
        </div>
      </div>
    </>
  );
};

export default withProfile(AgGridSubTable);
