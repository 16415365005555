import React, { useState } from "react";
// import logoDark from "../../../assets/images/logo-dark.png";
import logoDark from "../../../assets/images/45x45.png";
import { Alert } from "antd";
import axios from "axios";
import bcrypt from "bcryptjs";
import { useNavigate } from "react-router-dom";
import { withProfile } from "../../../contexts/profileContext";

const ChangePass = (props) => {
  const [identifier, setIdentifier] = useState(props.validatedProfile[0]);
  const [oldPass, setOldPass] = useState();
  const [newPass, setNewPass] = useState();
  const [confirmNewPass, setConfirmNewPass] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPass !== confirmNewPass) {
      alert("New password and Confirm password are no same...");
    } else {
      const oldHashedPassword = await hashWithFixedSalt(oldPass);
      const newHashedPassword = await hashWithFixedSalt(newPass);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/change-password`, {
          identifier: identifier,
          currentPassword: oldHashedPassword,
          newPassword: newHashedPassword,
        });
        // console.log("responce", response);
        alert(response.data.message);
        navigate("/login");
      } catch (error) {
        console.log("error", error.response.data.message);
        alert(error.response.data.message);
      }
      async function hashWithFixedSalt(password) {
        const fixedSalt = "$2b$10$yourfixedsaltforyourapplication";
        const hashedPassword = await bcrypt.hash(password, fixedSalt);

        return hashedPassword;
      }
    }
  };

  return (
    <>
      <div className="bg-overlay"></div>
      <div className="auth-bg">
        <div className="auth-page">
          <div>
            <div className="titel">
              <img src={logoDark} alt="logo" />
              <p className="titel_text">Tax-link</p>
            </div>
            <h4 className="text-muted mt-2" style={{ fontSize: 18 }}>
              Change Password
            </h4>
            <span className="text-muted">Change your Password with Tax-link.</span>
          </div>

          <div className="text-left mt-3">
            <form action="#" onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="Email">
                  Enail/Mobile<b className="text-danger">*</b>
                </label>
                <input type="text" className="form-control" id="Email" placeholder="Enter email/Mobile number" required value={identifier} onChange={(e) => setIdentifier(e.target.value)} />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="Email">
                  Old password<b className="text-danger">*</b>
                </label>
                <input type="text" className="form-control" id="Email" placeholder="Enter Old password" required onChange={(e) => setOldPass(e.target.value)} />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="Email">
                  New password<b className="text-danger">*</b>
                </label>
                <input type="text" className="form-control" id="Email" placeholder="Enter New password" required onChange={(e) => setNewPass(e.target.value)} />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="Email">
                  Confirm password<b className="text-danger">*</b>
                </label>
                <input type="text" className="form-control" id="Email" placeholder="Enter Confirm password" required onChange={(e) => setConfirmNewPass(e.target.value)} />
              </div>
              <div className="mt-3">
                <button className="btn btn-primary w-100" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withProfile(ChangePass);
